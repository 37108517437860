.card {
    background-color: #c5ffed !important;
    padding-top: 40px;
    padding-bottom: 100px;
    border: none !important;

}

.p-carousel-container button.p-carousel-prev {
    display: none !important;
}

@media (max-width:768px) {
    .card {
        padding-bottom: 30px !important;
    }
}