.board {
    border: 1px solid #000;
    margin: 20px auto;
}

.tile {
    width: 50px;
    height: 50px;
    display: inline-block;
}

.tile:hover {
    background-color: #e0e0e0;
}

.piece {
    font-size: 24px;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
}