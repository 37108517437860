.game_board {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.tile {
  width: 180px;
  height: 160px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  font-weight: bold;
  border-radius: 20px;
  margin: 10px;
}

.tile:hover {
  background-color: #504f4f;
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

canvas {
  cursor: pointer;
}