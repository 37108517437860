.chessboard {
  display: flex;
  /* flex-direction: row; */
  grid-template-columns: repeat(${props => props.gameData.board.columns}, 50px);
  grid-template-rows: repeat(${props => props.gameData.board.rows}, 50px);
  gap: 1px;
  border: 1px solid #000;
  background-color: #fff;
  width: (${props => props.gameData.board.columns * 50}px);
  flex-direction: column;
}

.row_direction {
  flex-direction: row;

}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c9c9c9;
  color: #000;
  font-size: 24px;
  padding: 25px;
  width: 100%;
}

.cell.white {
  background-color: #f0f0f0;
}

.game_object {
  font-size: 30px;
}

/* Add some additional styling to make it visually appealing */
.chessboard_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.game_info {
  margin-bottom: 20px;
  text-align: center;
}

.game_info h1 {
  margin: 0;
  background-color: #e4e4e4;
  border-radius: 13px;
  padding: 14px 24px;
}

.game_info p {
  margin: 5px 0;
}

.game_rules {
  text-align: left;
  margin-bottom: 20px;
}

.game_rules h2 {
  margin: 10px 0;
}

.game_rules ul {
  padding-left: 20px;
}

.game_rules li {
  margin: 5px 0;
}